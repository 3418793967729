const url = 'reporte/gastos';
import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';
import moment from "moment";

const LOADER_REPORTE_GASTOS = 'LOADER_REPORTE_GASTOS';
const SET_DATA_REPORTE_GASTOS = 'SET_DATA_REPORTE_GASTOS';
const SET_PAGE_REPORTE_GASTOS = 'SET_PAGE_REPORTE_GASTOS';
const SET_BUSCADOR_REPORTE_GASTOS = 'SET_BUSCADOR_REPORTE_GASTOS';
const SET_FILTRO_REPORTE_GASTOS = 'SET_FILTRO_REPORTE_GASTOS';
const SET_INICIAL_DATE_REPORTE_GASTOS = 'SET_INICIAL_DATE_REPORTE_GASTOS';
const SET_FINAL_DATE_REPORTE_GASTOS = 'SET_FINAL_DATE_REPORTE_GASTOS';
const SET_DETALLE_GASTOS_REPORTE_GASTOS = 'SET_DETALLE_GASTOS_REPORTE_GASTOS';
const SET_DATA_BANK_ACCOUNTS = 'SET_DATA_BANK_ACCOUNTS';
const SET_FILTRO_BANK_ACCOUNTS = 'SET_FILTRO_BANK_ACCOUNTS';

const generarExcel = () =>  (dispatch, getStore) => {
    const store = getStore();
    const search = store.reporte_gastos.buscador;

    const initial_date = store.reporte_gastos.initial_date;
    const final_date = store.reporte_gastos.final_date;
    //alert('initial date:', initial_date);

    const url_api = `/api/${url}/generarExcelGastos?initial_date=${initial_date}&final_date=${final_date}`

    fetch(url_api, {
        method: "GET",
        headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
        },
    })
    .then((response) => {
        console.log("responsito :: ", response);
        Swal(
            'success',
            "Se está generando el archivo, puede ir a la sección de tareas para ver el estado del reporte",
            'success'
        );        
    })
    .catch(() => {
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );        
    })
    .finally(() => {
        //dispatch(setLoaderExcel(false));
    });    
}; 

const listarCuentasBancarias = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_REPORTE_GASTOS, cargando: true});    
    let params = {page};

    api.get(`bank_accounts`, params).catch((error) => {        
        dispatch({type: LOADER_REPORTE_GASTOS, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        if(data){

            const newData = data.results.map(item => {
                return {value: item.id, label: item.owner};
            });
            newData.push({value: 0, label: 'Todos'});

            dispatch({type: SET_DATA_BANK_ACCOUNTS, bank_accounts: newData });
        }
        dispatch({type: LOADER_REPORTE_GASTOS, cargando: false});
    })
};

const listar = () =>  (dispatch, getStore) => {
    dispatch({type: LOADER_REPORTE_GASTOS, cargando: true});

    const store = getStore(); 
    const search = store.reporte_gastos.buscador;
    const filtro = store.reporte_gastos.filtro; 

    const initial_date = store.reporte_gastos.initial_date;
    const final_date = store.reporte_gastos.final_date;
    const bank_account = store.reporte_gastos.filtro_bank_account;

    let params = { search, initial_date, final_date, bank_account };
    if(filtro){
        params[''] = filtro
    }

    api.get(`${url}/getGastos`, params).then((response) => {
        console.log("Response: ", response)
        dispatch({type: SET_DATA_REPORTE_GASTOS, data: response});
    }).catch((error) => {
        console.log("Error ", error);
    }).finally(()=>{
        dispatch({type: LOADER_REPORTE_GASTOS, cargando: false});
    })
};

const search = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_REPORTE_GASTOS, buscador: search});
    dispatch(listar());
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_REPORTE_GASTOS, filtro});
    dispatch(listar());
};

const setFinalDate = (value) => (dispatch) => {
    dispatch({type: SET_FINAL_DATE_REPORTE_GASTOS, final_date: value});
    // dispatch(listar());
}

const setInitialDate = (value) => (dispatch) => {
    dispatch({type: SET_INICIAL_DATE_REPORTE_GASTOS, initial_date: value});
    // dispatch(listar());
}

const getDetalleGastos = (tipo_gasto, tipo) => (dispatch, getStore) => {
    const store = getStore();
    const initial_date = store.reporte_gastos.initial_date;
    const final_date = store.reporte_gastos.final_date;

    api.get(`${url}/getDetalleGastos`, {tipo_gasto, tipo,initial_date, final_date}).then((response) => {
        console.log("Response: ", response)
        dispatch({type: SET_DETALLE_GASTOS_REPORTE_GASTOS, detalle_gastos: response});

    }).catch((error) => {
        console.log("Error ", error);
    }).finally(()=>{
    })
}

const clearDetalleGastos = () => (dispatch) => {
    dispatch({type: SET_DETALLE_GASTOS_REPORTE_GASTOS, detalle_gastos: []});
}

const filtroBankAccounts = (filtro) => (dispatch) => {    
    dispatch({type: SET_FILTRO_BANK_ACCOUNTS, filtro_bank_account: filtro});    
}

export const actions = {
    listar,
    listarCuentasBancarias,
    filtroBankAccounts,
    search,
    filtro,
    setFinalDate,
    setInitialDate,
    generarExcel,
    getDetalleGastos,
    clearDetalleGastos,
};

export const reducer = {
    [LOADER_REPORTE_GASTOS]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_REPORTE_GASTOS]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_REPORTE_GASTOS]: (state, { page }) => {
        return {...state, page }
    },
    [SET_BUSCADOR_REPORTE_GASTOS]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_REPORTE_GASTOS]: (state, { filtro }) => {
        return {...state, filtro }
    },
    [SET_INICIAL_DATE_REPORTE_GASTOS]: (state, { initial_date }) => {
        return{
            ...state,
            initial_date
        }
    },
    [SET_FINAL_DATE_REPORTE_GASTOS]: (state, { final_date }) => {
        return{
            ...state,
            final_date
        }
    },
    [SET_DETALLE_GASTOS_REPORTE_GASTOS]: (state, { detalle_gastos }) => {
        return{
            ...state,
            detalle_gastos
        }
    },
    [SET_DATA_BANK_ACCOUNTS]: (state, { bank_accounts }) => {
        return {...state, bank_accounts }
    },
    [SET_FILTRO_BANK_ACCOUNTS]: (state, {filtro_bank_account}) => {
        return{
            ...state,
            filtro_bank_account
        }
    },
};

export const initialState = {
    cargando: false,
    page: 1,
    data: [],
    buscador: '',
    filtro: null,
    bank_account: 77,
    initial_date: moment().date(1).format("DD/MM/YYYY"),
    final_date: moment().format("DD/MM/YYYY"),
    detalle_gastos: [],
    bank_accounts: [],
    filtro_bank_account: 0
};

export default handleActions(reducer, initialState)
